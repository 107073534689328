<template lang="pug">
.code-insert-platform.mt-3.w-100
  om-body-text(bt400lg) {{ content || $t('codeInsertV2.bigcommerce.description') }}
  om-accordion.mt-6
    om-accordion-item(
      :step="1"
      :trackPrefix="getSetupGuideTrackPrefix('step1')"
      :done="isConnected"
    )
      template(#name) {{ $t('codeInsertV2.bigcommerce.steps.0.title') }}
      .code-insert-step-indent
        om-body-text(bt400md v-html="$t('codeInsertV2.bigcommerce.steps.0.texts.0')")
        insert-code(onlyCode)
    om-accordion-item(
      :step="2"
      :trackPrefix="getSetupGuideTrackPrefix('step2')"
      :done="isConnected"
    )
      template(#name) {{ $t('codeInsertV2.bigcommerce.steps.1.title') }}
      .code-insert-step-indent
        om-body-text(bt400md v-html="$t('codeInsertV2.bigcommerce.steps.1.texts.0')")
        om-body-text.mt-5(bt400md) {{ $t('codeInsertV2.bigcommerce.steps.1.texts.1') }}
        img.mt-3(
          :src="require('@/assets/admin/img/insert-code/platforms/bigcommerce/step_2_1.png')"
        )
        om-body-text.mt-5(bt400md) {{ $t('codeInsertV2.bigcommerce.steps.1.texts.2') }}
        img.mt-3(
          :src="require('@/assets/admin/img/insert-code/platforms/bigcommerce/step_2_2.png')"
        )
        om-body-text.mt-5(bt400md) {{ $t('codeInsertV2.bigcommerce.steps.1.texts.3') }}
        img.mt-3(
          :src="require('@/assets/admin/img/insert-code/platforms/bigcommerce/step_2_3.png')"
        )
        om-body-text.mt-5(bt400md) {{ $t('codeInsertV2.bigcommerce.steps.1.texts.4') }}
        img.mt-3(
          :src="require('@/assets/admin/img/insert-code/platforms/bigcommerce/step_2_4.png')"
        )
        om-body-text.mt-5(bt400md) {{ $t('codeInsertV2.bigcommerce.steps.1.texts.5') }}
        img.mt-3(
          :src="require('@/assets/admin/img/insert-code/platforms/bigcommerce/step_2_5.png')"
        )
      ul.mt-5
        li {{ $t('codeInsertV2.bigcommerce.steps.1.texts.6') }}
        li {{ $t('codeInsertV2.bigcommerce.steps.1.texts.7') }}
        li {{ $t('codeInsertV2.bigcommerce.steps.1.texts.8') }}
        li {{ $t('codeInsertV2.bigcommerce.steps.1.texts.9') }}
      .code-insert-step-indent.mb-3
        om-body-text.mt-5(bt400md) {{ $t('codeInsertV2.bigcommerce.steps.1.texts.10') }}
        img.mt-3(
          :src="require('@/assets/admin/img/insert-code/platforms/bigcommerce/step_2_6.png')"
        )
        om-body-text.mt-5(bt400md) {{ $t('codeInsertV2.bigcommerce.steps.1.texts.11') }}
        om-body-text.mt-5(bt400md) {{ $t('codeInsertV2.bigcommerce.steps.1.texts.12') }}
        img.mt-3.mb-3(
          :src="require('@/assets/admin/img/insert-code/platforms/bigcommerce/step_2_7.png')"
        )
</template>

<script>
  import childRouteMixin from '@/mixins/codeinsert/childRoute';
  import domainStatusMixin from '@/mixins/codeinsert/domainStatus';
  import platformMixin from '@/mixins/codeinsert/platform';

  export default {
    name: 'Bigcommerce',
    components: {
      InsertCode: () => import('@/components/CodeInsert/platforms/InsertCode.vue'),
    },
    mixins: [childRouteMixin, domainStatusMixin, platformMixin],
  };
</script>
